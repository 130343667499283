exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-empleados-js": () => import("./../../../src/pages/empleados.js" /* webpackChunkName: "component---src-pages-empleados-js" */),
  "component---src-pages-estudiantes-js": () => import("./../../../src/pages/estudiantes.js" /* webpackChunkName: "component---src-pages-estudiantes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lfp-js": () => import("./../../../src/pages/lfp.js" /* webpackChunkName: "component---src-pages-lfp-js" */),
  "component---src-pages-tucarnetplus-js": () => import("./../../../src/pages/tucarnetplus.js" /* webpackChunkName: "component---src-pages-tucarnetplus-js" */),
  "component---src-pages-ze-js": () => import("./../../../src/pages/ze.js" /* webpackChunkName: "component---src-pages-ze-js" */),
  "component---src-templates-tienda-jsx": () => import("./../../../src/templates/Tienda.jsx" /* webpackChunkName: "component---src-templates-tienda-jsx" */)
}

